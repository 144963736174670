import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  AppConstant,
  CONTENT_TYPE_ID,
  contentDetails,
  fetchContentListByType,
  getAzureFile,
  getContentDetails,
  getFilterCategoriesOptionsValues,
  getOnlyDate,
  getOnlyTime,
  getRegisterAndJoinLink,
  getViewAllListData,
  getWebinarDetails,
  ImageConstant,
  SortFilterOptions,
  translate,
  useAppSelector,
  viewDurationInHours,
  webinarDetailsSelector,
  convertUTCTime,
  openPdfOrWebview,
  fetchArticleDocumentLink,
  useAppDispatch,
  fetchParameters,
  API_CATEGORY_TYPES,
  savePageNoLeftWhileReading,
  SAML_REDIRECTOR_API_CALL,
  logEvent,
  REDIRECTION_SHALINA_CONTENT,
  getBlobFilePath,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  PageNames,
  PlatformNames,
  GAMIFICATION_ENABLED,
  listGamificationPointDistribution,
  GAMIFICATION_TYPE_UNIQUE_CODE,
  addOrUpdateUserGamificationPoints,
  getAnalyticPageNameByCategory,
  convertUTCDate,
  updateUserRecognition,
  handleRecognitionPopup,
  RECOGNITION_MESSAGE_TEXT,
  getUserDetails,
  getAnalyticsUserDetails,
  getLoggedInUserInfo,
  GAMIFICATION_ACTION_ID,
  getNotificationActionList,
  sendNotification,
} from '@shalina-app/shared';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  ArticleDetails,
  DetailPropsType,
  EBookDetails,
  ResourceDetails,
  VideoDetails,
} from '.';
import {
  BarLoader,
  BreadCrumb,
  Card,
  CircularLoader,
  ShalinaPDFViewer,
  Toast,
} from '../..';
import {
  DEFAULT_SHORT_TAKE,
  pageNames,
  VIDEO_TYPES,
} from '../../../app.constants';
import {
  convertToSpecialties,
  getImageSrc,
  getPageNameByCategory,
  retrieveData,
  STORAGE_CONSTANTS,
} from '../../../utils';
import styles from './detail.module.scss';
import { WebinarDetails } from './pages/webinarDetails/webinarDetails.component';
import { appInsights } from '../../../applicationInsight';
import { SignUpPopup } from '../../../features';

export const DetailPage: React.FC<DetailPropsType> = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const t = translate();
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);
  const userDetails = useAppSelector(getLoggedInUserInfo);
  const notificationList = useAppSelector(getNotificationActionList);

  // const eventId = retrieveData(STORAGE_CONSTANTS.EVENT_ID);
  const eventId = location?.state?.eventID;

  const user = retrieveData(STORAGE_CONSTANTS.USER);
  let detailsComponent;

  const pageName = params?.pageName;
  const contentTypeId: any = params?.contentTypeId;
  const contentId: any = params?.contentId;

  const [reload, setReload] = useState(false);
  const [relatedContentApiCall, setRelatedContentApiCall] = useState(false);
  const [viewPdf, setViewPdf] = useState(false);
  const [category, setCategory] = useState('');
  const [accessUrlLoader, setAccessUrlLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const [filterOptions, setFilterOptions] = useState<SortFilterOptions>({
    contentType: '',
    category: '',
    interest: '',
    sort: '',
    speciality: '',
  });
  const [pdfReaderInfo, setPdfReaderInfo] = useState({
    fileUrl: '',
    pageNumber: 0,
  });
  const [showToast, setShowToast] = useState(false);
  const [eventButtonText, setEventButtonText] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSubMessage, setToastSubMessage] = useState('');
  const [initialAccessUrl, setInitialAccessUrl] = useState('');
  const [uniqueContentTypeId, setUniqueContentTypeId] = useState('');
  const [contentDetailsData, setContentDetailsData] = useState<any>({});
  const [ctaAction, setCtaAction] = useState('');
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [pdfOpen, setPdfOpen] = useState(false);
  const [startReadingLoader, setStartReadingLoader] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  const contentDetailsResponse = useAppSelector(
    eventId ? webinarDetailsSelector : getContentDetails
  );
  const masterContentTypeData = useAppSelector(
    getFilterCategoriesOptionsValues
  );
  const azureReadFileResponse: any = useAppSelector(getAzureFile);
  const sideList = useAppSelector(getViewAllListData);
  const contentSideListResponse = sideList?.filter(
    (element: any) => element.nid !== contentId
  );

  const isContentDetailsLoading = useAppSelector(
    (state: any) => state?.home?.contentDetails?.isContentDetailsLoading
  );
  const isMasterDataLoading = useAppSelector(
    (state: any) => state?.common?.masterData?.masterDataLoading
  );

  const isWebinarContentDetailsLoading = useAppSelector(
    (state) =>
      state?.learningCenter?.webinarContentDetails?.isWebinarDetailsLoading
  );
  const isAzureFileUrlLoading = useAppSelector(
    (state) => state?.common?.azureFileUrl.isAzureFileUrlLoading
  );
  const isContentTypeListingLoading = useAppSelector(
    (state) => state?.knowledgehub?.viewAllContent?.isAllContentLoading
  );

  const gamificationPointDistributionData = useAppSelector(
    listGamificationPointDistribution
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    setReload(true);
    setViewPdf(false);
  }, []);

  useEffect(() => {
    if (masterContentTypeData && masterContentTypeData?.length > 0) {
      fetchDataWithMasterContent();
    }
  }, [masterContentTypeData, contentTypeId]);

  const fetchDataWithMasterContent = () => {
    const category: any = masterContentTypeData?.find(
      (item: any) => item?.value === contentTypeId
    )?.category;

    const uniqueContentTypeId: any = masterContentTypeData?.find(
      (item: any) => item?.value === contentTypeId
    )?.unique_id;

    if (uniqueContentTypeId) {
      setUniqueContentTypeId(uniqueContentTypeId);
    }

    if (category) {
      setCategory(category);
      setFilterOptions((prevOptions) => ({
        ...prevOptions,
        category: category,
      }));
    }
  };

  useEffect(() => {
    if (reload && category) {
      loadApi();
      setReload(false);
    }
  }, [reload, category]);

  // useEffect(() => {
  //   if (contentDetailsResponse && contentDetailsResponse.length > 0) {
  //     setContentDetailsData(contentDetailsResponse?.[0]);
  //     settingEventButtonText(contentDetailsResponse);
  //   } else {
  //     setContentDetailsData({});
  //   }
  // }, [contentDetailsResponse]);

  useEffect(() => {
    if (relatedContentApiCall) {
      loadContentTypeListing();
      setRelatedContentApiCall(false);
    }
  }, [relatedContentApiCall]);

  const loadApi = async () => {
    if (eventId) {
      const payload = {
        id: contentId,
        eventId: eventId,
        emailId: user?.emailId ? user.emailId : '',
      };
      const response = await dispatch(
        getWebinarDetails(payload) as any
      ).unwrap();

      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200 &&
        response?.data &&
        response?.data?.[0]
      ) {
        setContentDetailsData(response?.data?.[0]);
        settingEventButtonText(response?.data);
      } else {
        setContentDetailsData({});
      }
    } else if (contentId) {
      const payload = {
        id: contentId,
        obj: {
          category: category,
        },
      };
      const response = await dispatch(contentDetails(payload) as any).unwrap();

      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200 &&
        response?.data &&
        response?.data?.[0]
      ) {
        if (
          pageName === pageNames.LearningCenter &&
          response?.data?.[0]?.event_id
        ) {
          const payload = {
            id: contentId,
            eventId: response?.data?.[0]?.event_id,
            emailId: user?.emailId ? user.emailId : '',
          };
          const res = await dispatch(
            getWebinarDetails(payload) as any
          ).unwrap();

          if (
            res &&
            res?.status === 'SUCCESS' &&
            res?.statusCode === 200 &&
            res?.data &&
            res?.data?.[0]
          ) {
            setContentDetailsData(res?.data?.[0]);
            settingEventButtonText(res?.data);
          } else {
            setContentDetailsData({});
          }
        } else {
          setContentDetailsData(response?.data?.[0]);
          settingEventButtonText(response?.data);
        }
      } else {
        setContentDetailsData({});
      }
    }
    setAccessUrlLoader(true);
    setRelatedContentApiCall(true);
  };

  const settingEventButtonText = (result: any) => {
    // setting state and passing value to child to handle button toggle and maintan the API call in parent component
    if (result?.[0]?.event_data?.event_status == 'past') {
      setEventButtonText(AppConstant.COMMON.WATCH_ON_DEMAND);
    } else if (result?.[0]?.event_data?.is_registered) {
      setEventButtonText(AppConstant.COMMON.LAUNCH);
    } else {
      setEventButtonText(AppConstant.COMMON.REGISTER_NOW_CTA);
    }
  };

  const loadContentTypeListing = () => {
    dispatch(
      fetchContentListByType({
        contentId: contentTypeId,
        filterOptions: eventId
          ? {
              ...filterOptions,
              category: category,
              skip: 0,
              take: DEFAULT_SHORT_TAKE,
              event_type: '',
            }
          : {
              ...filterOptions,
              category: category,
              skip: 0,
              take: DEFAULT_SHORT_TAKE,
            },
      }) as any
    );
  };

  useEffect(() => {
    if (contentDetailsData) {
      if (
        contentDetailsData?.field_select_article_type &&
        contentDetailsData?.title
      ) {
        logEvent(
          appInsights,
          'pageView',
          `${contentDetailsData?.field_select_article_type} > ${contentDetailsData?.title}`
        );
        if (isLoggedIn && user && contentDetailsData) {
          const userObj = userDetails || user;
          const userInfo = getAnalyticsUserDetails(userObj);
          logEvent(
            appInsights,
            'custom',
            CUSTOM_ANALYTICS_EVENT_NAMES.USER_MOST_VIEWED,
            {
              userEmail: userInfo?.emailId,
              userMobile: userInfo?.mobile,
              userName: userInfo?.name,
              userQualification: userInfo?.qualification,
              userInterest: userInfo?.areaOfInterest,
              userSpecialization: userInfo?.specialization,
              userCountryOfPractice: userInfo?.countryOfPractice,
              userPreferredLanguage: userInfo?.preferredLanguage,
              contentId: contentDetailsData?.id || '',
              contentTitle: contentDetailsData?.title || '',
              platform: PlatformNames.WEB,
            }
          );
          logEvent(
            appInsights,
            'custom',
            CUSTOM_ANALYTICS_EVENT_NAMES.USER_MOST_PAGE_VIEWED,
            {
              userEmail: userInfo?.emailId,
              userMobile: userInfo?.mobile,
              userName: userInfo?.name,
              userQualification: userInfo?.qualification,
              userInterest: userInfo?.areaOfInterest,
              userSpecialization: userInfo?.specialization,
              userCountryOfPractice: userInfo?.countryOfPractice,
              userPreferredLanguage: userInfo?.preferredLanguage,
              contentId: contentDetailsData?.id || '',
              contentTitle: contentDetailsData?.title || '',
              category: contentDetailsData?.field_select_article_type || '',
              moduleName:
                getAnalyticPageNameByCategory(
                  contentDetailsData?.field_module_type
                ) || '',
              pageName: `${contentDetailsData?.field_select_article_type} ${PageNames?.DETAIL}`,
              platform: PlatformNames.WEB,
            }
          );
        }
      }
      const viewMode = openPdfOrWebview(
        contentDetailsData,
        contentDetailsData?.field_module_type
      );
      setCtaAction(viewMode);
    }
  }, [contentDetailsData]);

  const saveVideoTimeStamp = (lastReadAt: any) => {
    const payload = {
      id: contentId,
      lastreadat: lastReadAt,
    };
    dispatch(savePageNoLeftWhileReading(payload));
  };

  const sendNotificationForMilestoneUpdate = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code === GAMIFICATION_ACTION_ID.UPDATE_MILESTONE
    )?.title;

    const req = {
      action: action,
      device: 'web',
    };
    // Hide Continue where you left feature
    await dispatch(sendNotification(req));
  };

  const updateGamificationPoints = async () => {
    if (isLoggedIn && GAMIFICATION_ENABLED) {
      checkRecognition();
      const gamificationDataItem = gamificationPointDistributionData?.find(
        (item: any) =>
          item.field_unique_code === GAMIFICATION_TYPE_UNIQUE_CODE.READ_CONTENT
      );
      if (gamificationDataItem && gamificationDataItem?.field_unique_code) {
        const payload = {
          field_unique_code: gamificationDataItem?.field_unique_code,
          entityTypeId: contentId,
        };
        const response: any = await dispatch(addOrUpdateUserGamificationPoints(payload)).unwrap();
        if(response && response?.info) {
          sendNotificationForMilestoneUpdate()
        }
      }
    }
  };

  const checkRecognition = async () => {
    if (contentId) {
      const response = await dispatch(
        updateUserRecognition({
          view: 1,
          contentId: contentId,
        })
      ).unwrap();
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200 &&
        response?.message === RECOGNITION_MESSAGE_TEXT.AVID_READER &&
        response?.data
      ) {
        const obj = {
          showRecognitionPopup: true,
          recognitionData: response?.data,
        };
        dispatch(handleRecognitionPopup(obj));
      } else {
        const obj = {
          showRecognitionPopup: false,
          recognitionData: {},
        };
        dispatch(handleRecognitionPopup(obj));
      }
    }
  };

  const handleNextStep = async () => {
    setStartReadingLoader(true);
    const lastReadAt = contentDetailsData?.last_read_at
      ? parseInt(contentDetailsData?.last_read_at)
      : 0;
    try {
      if (isLoggedIn) {
        if (contentDetailsData?.field_is_ebsco) {
          if (
            contentDetailsData?.field_module_type ===
            API_CATEGORY_TYPES.SHALINA_TV
          ) {
            saveVideoTimeStamp(0);
            if (REDIRECTION_SHALINA_CONTENT) {
              window.open(contentDetailsData?.event_data?.url, '_self');
            } else {
              setLoading(true);
              setUrl(contentDetailsData?.event_data?.url);
              setOpen(true);
            }
            setStartReadingLoader(false);
          } else {
            const parameter = fetchParameters(contentDetailsData?.event_data);
            const response = await dispatch(
              fetchArticleDocumentLink(parameter as any)
            ).unwrap();

            if (
              response &&
              response?.status === 'SUCCESS' &&
              response?.statusCode === 200
            ) {
              setStartReadingLoader(false);
              if (response?.data?.isPdfLink) {
                const pdfReaderInfo = {
                  fileUrl: response?.data?.url,
                  pageNumber: lastReadAt,
                };
                setPdfOpen(true);
                setPdfReaderInfo(pdfReaderInfo);
              } else if (response?.data?.isSAML) {
                if (SAML_REDIRECTOR_API_CALL) {
                  window.open(response?.data?.url, '_self');
                }
              }
            } else if (response?.status === 'ERROR' && response?.message) {
              setStartReadingLoader(false);
              setShowToast(true);
              setIsSuccess(false);
              setToastMessage(t(response?.message));
            }
          }
        } else {
          setStartReadingLoader(false);
          // if (azureReadFileResponse && azureReadFileResponse?.error) {
          //   setShowToast(true);
          //   setIsSuccess(false);
          //   setToastMessage(t(AppConstant.COMMON.PDF_LOADING_FIELD));
          //   setViewPdf(false);
          // } else {
          const pdfReaderInfo = {
            fileUrl:
              getBlobFilePath(contentDetailsData?.field_supporting_docs) || '',
            pageNumber: lastReadAt,
          };

          setPdfReaderInfo(pdfReaderInfo);
          setViewPdf(true);
          // }
        }
        updateGamificationPoints();
        setShowSignUp(false);
      } else {
        setShowSignUp(true);
        setStartReadingLoader(false);
      }
    } catch (error) {
      setStartReadingLoader(false);
    }
  };

  useEffect(() => {
    if (azureReadFileResponse && !azureReadFileResponse?.error) {
      setInitialAccessUrl(azureReadFileResponse);
    }
  }, [azureReadFileResponse]);

  const handleToastClose = () => {
    setShowToast(false);
  };

  const handleClose = () => setOpen(false);
  const handlePdfClose = () => setPdfOpen(false);

  const contentType = contentDetailsData?.field_select_article_type
    ? contentDetailsData?.field_select_article_type
    : '';

  const handleWebinarLink = async (payload: any, isPastWebinar: boolean) => {
    try {
      setButtonLoader(true);
      const response = await dispatch(
        getRegisterAndJoinLink(payload) as any
      ).unwrap();

      if (
        payload?.action === 'register' &&
        response &&
        response?.statusCode === 200 &&
        response?.status === 'SUCCESS'
      ) {
        if (isLoggedIn && user && contentDetailsData) {
          const userObj = userDetails || user;
          const userInfo = getAnalyticsUserDetails(userObj);
          logEvent(
            appInsights,
            'custom',
            CUSTOM_ANALYTICS_EVENT_NAMES.USER_WEBINAR_REGISTRATION,
            {
              userEmail: userInfo?.emailId,
              userMobile: userInfo?.mobile,
              userName: userInfo?.name,
              userQualification: userInfo?.qualification,
              userInterest: userInfo?.areaOfInterest,
              userSpecialization: userInfo?.specialization,
              userCountryOfPractice: userInfo?.countryOfPractice,
              userPreferredLanguage: userInfo?.preferredLanguage,
              eventName: contentDetailsData?.title,
              eventStaus: contentDetailsData?.event_data?.event_status,
              platform: PlatformNames.WEB,
            }
          );
        }
      }
      if (
        eventButtonText === AppConstant.COMMON.LAUNCH ||
        eventButtonText === AppConstant.COMMON.WATCH_ON_DEMAND
      ) {
        window.location.href = response?.data?.user?.unique_join_link;
      } else {
        setEventButtonText(AppConstant.COMMON.LAUNCH);
        setShowToast(true);
        setIsSuccess(response?.statusCode === 200);
        setToastMessage(response?.message);
      }
    } catch (error) {
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(AppConstant.COMMON.COMMON_ERROR));
    } finally {
      setButtonLoader(false);
    }
  };

  if (contentDetailsData && uniqueContentTypeId) {
    switch (uniqueContentTypeId) {
      case CONTENT_TYPE_ID.E_BOOKS:
        detailsComponent = (
          <EBookDetails
            contentData={contentDetailsData}
            ctaAction={ctaAction}
            handleNextStep={handleNextStep}
          />
        );
        break;
      case CONTENT_TYPE_ID.RESOURCES:
        detailsComponent = (
          <ResourceDetails
            contentData={contentDetailsData}
            handleNextStep={handleNextStep}
          />
        );
        break;
      case CONTENT_TYPE_ID.ARTICLE:
        detailsComponent = (
          <ResourceDetails
            contentData={contentDetailsData}
            handleNextStep={handleNextStep}
          />
        );
        break;
      case CONTENT_TYPE_ID.SURGURIES_AND_PROCEDURES:
        detailsComponent = (
          <VideoDetails
            ctaAction={ctaAction}
            contentData={contentDetailsData}
            handleNextStep={handleNextStep}
          />
        );
        break;
      case CONTENT_TYPE_ID.CLINICAL_ASSESSMENT_PROTOCOL:
        detailsComponent = (
          <VideoDetails
            ctaAction={ctaAction}
            contentData={contentDetailsData}
            handleNextStep={handleNextStep}
          />
        );
        break;
      case CONTENT_TYPE_ID.DISEASES_AND_CONDITIONS:
        detailsComponent = (
          <VideoDetails
            ctaAction={ctaAction}
            contentData={contentDetailsData}
            handleNextStep={handleNextStep}
          />
        );
        break;
      case CONTENT_TYPE_ID.PRACTICE_ESSENTIALS:
        detailsComponent = (
          <VideoDetails
            ctaAction={ctaAction}
            contentData={contentDetailsData}
            handleNextStep={handleNextStep}
          />
        );
        break;
      case CONTENT_TYPE_ID.WEBINARS:
        detailsComponent = (
          <WebinarDetails
            buttonLoader={buttonLoader}
            buttonText={eventButtonText}
            handleWebinarLink={handleWebinarLink}
            contentData={contentDetailsData}
          />
        );
        break;
      default:
        detailsComponent = (
          <ArticleDetails
            ctaAction={ctaAction}
            contentData={contentDetailsData}
            handleNextStep={handleNextStep}
            startReadingLoader={startReadingLoader}
            category={category}
          />
        );
        break;
    }
  }

  const handleLoad = () => {
    setLoading(false);
  };

  const handleSignUpClose = () => {
    setShowSignUp(false);
  };

  const page = getPageNameByCategory(category);
  const isEmpty = Object.keys(contentDetailsData).length === 0 ? true : false;
  const { DropDownArrowIcon } = ImageConstant.SVG_ICONS;
  return (
    <>
      {(isContentDetailsLoading ||
        isWebinarContentDetailsLoading ||
        (isAzureFileUrlLoading &&
          (contentDetailsData?.field_supporting_docs ||
            contentDetailsData?.field_upload_video_trailer))) && <BarLoader />}
      <div
        className={`${styles.detailPage} ${
          VIDEO_TYPES.some((type) =>
            contentType?.toLowerCase().includes(type)
          ) && styles.isVideo
        }`}
      >
        <div className="cust-container">
          {!isEmpty &&
            !isMasterDataLoading &&
            !isContentDetailsLoading &&
            !isWebinarContentDetailsLoading && (
              <BreadCrumb titleProps={contentDetailsData?.title} />
            )}
          <div className={styles.detailRow}>
            <div className={styles.detailCol8}>
              {/* {isContentDetailsLoading || isWebinarContentDetailsLoading ? (
                <>
                  <div className={styles.loadingHeight}></div>
                  <CircularLoader size={50} thickness={4} />
                </>
              ) :  */}
              {!isMasterDataLoading &&
              !isContentDetailsLoading &&
              !isWebinarContentDetailsLoading &&
              contentDetailsData &&
              !isEmpty ? (
                <div className={styles.detailWrapper}>
                  {contentDetailsData && !viewPdf ? (
                    detailsComponent
                  ) : (
                    <ShalinaPDFViewer
                      fileUrl={pdfReaderInfo.fileUrl}
                      pageNumber={pdfReaderInfo.pageNumber}
                      id={contentId}
                    />
                  )}
                  {/* <div className={styles.guestDesc}>
                    <div className={styles.signUpSec}>
                      <Link to={urls.signInPage}>
                        <button>
                          {t(
                            AppConstant.AUTHENTICATION_PAGE.SIGN_In_TO_CONTINUE
                          )}
                        </button>
                      </Link>
                    </div>
                  </div> */}
                </div>
              ) : (
                !isMasterDataLoading &&
                !isContentDetailsLoading &&
                !isWebinarContentDetailsLoading &&
                contentDetailsData?.length === 0 && (
                  <div className={styles.contentNotFound}>
                    <div className={styles.notFoundImg}>
                      <img
                        src={ImageConstant.SOURCE.contentNotFoundImg}
                        alt="not found"
                      />
                    </div>
                    <div className={styles.notFoundText}>
                      <h3>{`${t(AppConstant.HOME_PAGE.NO_TEXT)} ${t(
                        AppConstant.HOME_PAGE.DATA_TEXT
                      )} ${t(AppConstant.HOME_PAGE.FOUND_TEXT)}`}</h3>
                      <p>{t(AppConstant.HOME_PAGE.NO_DATA_FOUND_MESSAGE)}</p>
                    </div>
                  </div>
                )
              )}
            </div>
            <div className={styles.detailCol4}>
              {!viewPdf && (
                <div className={styles.relatedWeb}>
                  {isContentTypeListingLoading ? (
                    <></>
                  ) : (
                    <>
                      {contentType && contentSideListResponse?.length > 0 && (
                        <div className={styles.title}>
                          {t(AppConstant.HOME_PAGE.RELATED_WEBINARS)}{" "}
                          {contentType}
                        </div>
                      )}
                      {contentSideListResponse &&
                        contentSideListResponse?.length > 0 &&
                        contentSideListResponse?.map((item: any) => (
                          <div className={styles.relatedCard}>
                            <Card
                              id={item?.nid}
                              contentType={
                                item?.field_select_article_type || ""
                              }
                              contentTypeId={
                                item?.field_select_article_type_id || ""
                              }
                              title={item?.title || ""}
                              speciality={convertToSpecialties(
                                item?.field_area_of_interest || ""
                              )}
                              date={
                                item?.field_unique_code ===
                                CONTENT_TYPE_ID?.WEBINARS
                                  ? convertUTCDate(item?.field_starts_on)
                                  : getOnlyDate(item?.field_authored_on)
                              }
                              time={
                                item?.field_unique_code ===
                                CONTENT_TYPE_ID?.WEBINARS
                                  ? convertUTCTime(item?.field_starts_on)
                                  : getOnlyTime(item?.field_authored_on)
                              }
                              publishedName={item?.field_authored_by || ""}
                              viewsCount={item?.view_count || ""}
                              duration={viewDurationInHours(
                                item?.field_duration || ""
                              )}
                              thumbnailImage={getImageSrc(
                                item?.field_thumbnail_image
                              )}
                              pageName={page}
                              pageRoute={""}
                              isBookmarked={item?.is_bookmarked}
                              mostViewed={item?.most_viewed}
                              eventId={item?.event_id}
                              invokeCardClick={() => setReload(true)}
                              uniqueContentTypeId={item?.field_unique_code}
                              category={item?.type}
                              tags={item?.field_tags}
                            />
                          </div>
                        ))}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <Toast
          showToast={showToast}
          isSuccess={isSuccess}
          message={toastMessage}
          subMessage={toastSubMessage}
          handleToastClose={handleToastClose}
        />

        <Dialog
          fullScreen
          open={pdfOpen}
          onClose={handlePdfClose}
          className={styles.ebescoModal}
        >
          <DialogContent>
            <div>
              <div className={styles.closeDiv}>
                <button className={styles.backBtn} onClick={handlePdfClose}>
                  <DropDownArrowIcon />
                  {t(AppConstant.COMMON.BACK_CTA)}
                </button>
                {/* <span onClick={handlePdfClose}>&times;</span> */}
              </div>
              <ShalinaPDFViewer
                fileUrl={pdfReaderInfo?.fileUrl}
                pageNumber={pdfReaderInfo?.pageNumber}
                id={contentId}
              />
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          className={styles.ebescoModal}
        >
          <DialogContent>
            <div className={styles.closeDiv}>
              {loading && <CircularLoader size={50} thickness={4} />}
              <>
                <button className={styles.backBtn} onClick={handleClose}>
                  <DropDownArrowIcon />
                  {t(AppConstant.COMMON.BACK_CTA)}
                </button>
                {/* <span onClick={handleClose}>&times;</span> */}
              </>
              {/* <iframe src={ebescoUrl} className={styles.iframe} /> */}
            </div>
            {/* <object
              data={url}
              className={styles.iframe}
              id='ebsco-id'
              onLoad={handleLoad}
            ></object> */}
            <iframe
              src={url}
              className={styles.iframe}
              id="ebsco-id"
              onLoad={handleLoad}
              sandbox="allow-scripts allow-same-origin"
            ></iframe>
          </DialogContent>
        </Dialog>
      </div>
      <SignUpPopup open={showSignUp} handleClose={handleSignUpClose} />
    </>
  );
};
